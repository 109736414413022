import { notification } from "antd";

import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const getAnalytics = async (query: { [key: string]: string | number }) => {
  try {
    const url = `${BASE_URL_BETA}/analytic`;
    const response = await axios.get(url, { params: query });

    if (response && response.status === 200) {
      return window.open(response.data.link);
    }
  } catch (err: any) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
    throw new Error(err);
  }
};

export default getAnalytics;

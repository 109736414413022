/* eslint-disable no-useless-escape */
import { useState, useEffect } from "react";
import axios from "../../config/axios";

// component
import { Layout, Form, Input, Button, notification, Table, Modal } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import Sidebar from "../../component/Sidebar";

// constant
import { BASE_URL_BETA } from "../../constant/api";

const TermsCondition = () => {
  const [tnc, setTnc] = useState("");
  const [policy, setPolicy] = useState("");
  const [support, setSupport] = useState("");
  const [currentLink, setCurrentLink] = useState<{ name: string; url: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [form] = Form.useForm();

  const handleUpdateLink = async () => {
    try {
      setIsLoading(true);
      const response = await axios.put(`${BASE_URL_BETA}/v`, {
        tnc_link: tnc,
        policy_link: policy,
        support_link: support,
      });

      if (response.status === 200) {
        notification.success({ message: "Berhasil memperbaharui" });
        getCurrentLink();
        setShowModalConfirmation(false);
        setPolicy("");
        setTnc("");
        setSupport("");
      }
    } catch (err) {
      notification.error({ message: "Gagal memperbaharui" });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    setShowModalConfirmation(true);
  };

  const getCurrentLink = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL_BETA}/v`);

      if (response.status === 200) {
        const { tnc_link, policy_link, support_link } = response.data.version;
        setCurrentLink([
          { name: "Privacy and Policy", url: policy_link },
          { name: "Terms and Condition", url: tnc_link },
          { name: "Support", url: support_link },
        ]);
      }
    } catch (err) {
      notification.error({ message: "Gagal memuat" });
    } finally {
      setIsLoading(false);
    }
  };

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 14,
    },
  };

  useEffect(() => {
    getCurrentLink();
  }, []);

  const columns = [
    {
      title: "Link",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "URL Path",
      dataIndex: "url",
      key: "url",
    },
  ];

  return (
    <>
      <Modal
        visible={showModalConfirmation}
        title='Perhatian'
        onCancel={() => setShowModalConfirmation(false)}
        onOk={() => handleUpdateLink()}
      >
        Apakah kamu yakin mengubah link ini ?
      </Modal>

      <Layout style={{ height: "100vh" }} {...formItemLayout}>
        <Sider breakpoint='lg' collapsedWidth='0'>
          <Sidebar activeTab={"tnc"} />
        </Sider>
        <Layout>
          <Header style={{ background: "#fff" }}>
            <h1>Link management</h1>
          </Header>
          <Content
            style={{
              padding: "24px",
              paddingBottom: "100px",
              overflowY: "scroll",
            }}
          >
            <Table
              style={{ marginBottom: "20px" }}
              dataSource={currentLink}
              columns={columns}
              pagination={{ hideOnSinglePage: true }}
            />
            <Form {...formItemLayout} layout='vertical' form={form} onFinish={onSubmit}>
              <Form.Item
                label='New Terms & Condition Link'
                name='tnc'
                rules={[
                  { required: true, message: "URL link tidak boleh kosong" },
                  {
                    pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                    message: "URL tidak valid",
                  },
                ]}
              >
                <Input onChange={(e) => setTnc(e.target.value)} value={tnc} />
              </Form.Item>
              <Form.Item
                name='privacy'
                label='New Policy & Privacy Link'
                rules={[
                  { required: true, message: "URL link tidak boleh kosong" },
                  {
                    pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                    message: "URL tidak valid",
                  },
                ]}
              >
                <Input onChange={(e) => setPolicy(e.target.value)} value={policy} />
              </Form.Item>
              <Form.Item
                name='support'
                label='Support Link'
                rules={[
                  { required: true, message: "URL link tidak boleh kosong" },
                  {
                    pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                    message: "URL tidak valid",
                  },
                ]}
              >
                <Input onChange={(e) => setSupport(e.target.value)} value={policy} />
              </Form.Item>
              <Form.Item>
                <Button type='primary' loading={isLoading} htmlType='submit'>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default TermsCondition;

import { notification } from "antd";

import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const postSubscriptionPackage = async (data: {
  title: string;
  description: string;
  monthlyFee: number;
  activePeriod: number;
  isActive: string;
  discountType: string;
  discountAmount: number;
}) => {
  const { title, description, monthlyFee, activePeriod, isActive, discountType, discountAmount } = data;
  try {
    const url = `${BASE_URL_BETA}/admin/subscription`;
    const payload = {
      title: title,
      description: description,
      monthly_fee: monthlyFee,
      active_period: activePeriod,
      is_active: isActive === "active" ? true : false,
      discount_type: discountType,
      discount_amount: discountAmount,
    };

    const response = await axios.post(url, payload);

    if (response && response.status === 201) {
      notification.success({
        message: "Berhasil menambah subscription package",
      });
      return response.data;
    }
  } catch (err: any) {
    notification.error({
      message: "Gagal memperbaharui",
      placement: "topRight",
      duration: 2,
    });
    throw new Error(err);
  }
};

export default postSubscriptionPackage;

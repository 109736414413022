/* eslint-disable array-callback-return */
import { notification } from "antd";
import axios from "../../../config/axios";
import { BASE_URL_BETA } from "../../../constant/api";

export const getSubscriptionPackage = async () => {
  try {
    const url = `${BASE_URL_BETA}/admin/subscription/list`;

    const response = await axios.get(url);
    if (response.status === 200) {
      let { subscription_package, total_data } = response.data;
      subscription_package.map((e: any) => {
        // eslint-disable-next-line eqeqeq
        e.is_active = e.is_active == 1 ? "Aktif" : "Draft/tidak aktif";
      });

      return { packageList: subscription_package, totalData: total_data || 0 };
    }

    return { packageList: [], totalData: 0 };
  } catch (err) {
    notification.error({ message: "Gagal memuat" });
    return { packageList: [], totalData: 0 };
  }
};

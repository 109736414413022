import { notification } from "antd";

import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const deleteSubcriptionPackage = async (id: any) => {
  try {
    const response = await axios.delete(`${BASE_URL_BETA}/admin/subscription/${id}`);
    if (response.status === 201) {
      notification.success({
        message: "Berhasil menghapus subscription package",
      });
    }
  } catch (err) {
    notification.error({ message: "Gagal menghapus subscription package" });
  }
};

export default deleteSubcriptionPackage;

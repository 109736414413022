import axios from "axios";
import { notification } from "antd";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const postLogin = async (identifier: string, password: string) => {
  try {
    const payload = {
      identifier,
      password,
    };

    const url = `${BASE_URL_BETA}/admin/signin`;
    const response = await axios.post(url, payload);

    if (response && response.status === 200) {
      localStorage.setItem("token", response.data.data.AuthenticationResult?.IdToken);
      localStorage.setItem("accessToken", response.data.data.AuthenticationResult?.AccessToken);
      localStorage.setItem("refreshToken", response.data.data.AuthenticationResult?.RefreshToken);

      localStorage.setItem("userRole", response.data.userRole);
      return;
    }
  } catch (err: any) {
    notification.error({
      message: "Gagal login",
      description: "Periksa email dan password-mu",
      placement: "topRight",
      duration: 3,
    });
    throw new Error(err);
  }
};

export default postLogin;

/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from "antd";
import { useEffect, useMemo, useState } from "react";

// utils
import { getMonitorSystemResourcesLog } from "./utils/getMonitorData";

// type
import { MonitorSystemResourcesLog } from "../../types/monitorSystemResources";

// component
import moment from "moment";

type Props = {
  system_resource_id: number;
};

const MonitorSystemResourcesLogComponent = (props: Props) => {
  const { system_resource_id } = props;

  const [list, setList] = useState<MonitorSystemResourcesLog[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter] =
    useState<any>(/* {
    date_start: moment().format("YYYY-MM-DD 00:00:00"),
    date_end: moment().format("YYYY-MM-DD 23:59:59"),
  } */);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 50,
  });

  const handleTableChange = async (pagination: any, filters: any, sorter: any) => {
    try {
      setIsLoading(true);
      const listMonitorSystemResources = await getMonitorSystemResourcesLog(system_resource_id, {
        ...filter,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...pagination,
        ...filters,
      });
      setList(listMonitorSystemResources.collections);
      setPagination({
        ...pagination,
        total: listMonitorSystemResources.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const columns = [
    {
      title: "Alarm Type",
      dataIndex: "alarm_type",
      key: "alarm_type",
    },
    {
      title: "Alarm Name",
      dataIndex: "alarm_name",
      key: "alarm_name",
    },
    {
      title: "Alarm State",
      dataIndex: "alarm_state",
      key: "alarm_state",
    },
    {
      title: "Alarm Descriptions",
      dataIndex: "alarm_descriptions",
      key: "alarm_descriptions",
    },
    {
      title: "Alarm Time",
      dataIndex: "alarm_time",
      key: "alarm_time",
    },
  ];

  const getMonitorSystemResources = async () => {
    try {
      setIsLoading(true);
      const listMonitorSystemResources = await getMonitorSystemResourcesLog(system_resource_id, {
        ...pagination,
        ...filter,
      });
      setList(listMonitorSystemResources.collections);
      setPagination({
        ...pagination,
        total: listMonitorSystemResources.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const renderDate = (date: string | null | undefined, includeHour?: boolean) => {
    const format = includeHour ? "DD MMM YYYY, HH:mm" : "DD MMM YYYY";
    return date ? moment(date).format(format) : "-";
  };

  const getFormattedList = useMemo(() => {
    let result: MonitorSystemResourcesLog[] = list.map((p: MonitorSystemResourcesLog) => {
      return {
        id: p.id,
        alarm_type: p.alarm_type,
        alarm_name: p.alarm_name,
        alarm_state: p.alarm_state,
        alarm_descriptions: p.alarm_descriptions,
        alarm_time: p.alarm_time,
        created_at: renderDate(p.created_at, true),
      };
    });

    return result;
  }, [list, filter]);

  useEffect(() => {
    getMonitorSystemResources();
  }, [system_resource_id, filter]);

  // const onSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFilter({ ...filter, [e.target.name]: e.target.value });
  // }, 800);

  return (
    <>
      {/* <Row style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <RangePicker
            // defaultValue={[moment(), moment()]}
            style={{ width: "100%" }}
            onChange={onDateChange}
            onOk={onDateOK}
          />
        </Col>
      </Row> */}
      <Table
        rowKey={(record) => record.id}
        dataSource={getFormattedList}
        columns={columns}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default MonitorSystemResourcesLogComponent;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Layout, Table, Row, Col, Modal, DatePicker, Input, Select } from "antd";
import moment from "moment";
import debounce from "lodash/debounce";

// constant
import { RESPONSE_CODE } from "../../constant/responseCode";

// component
import LoggerDetail from "./LoggerDetail";
import Sidebar from "../../component/Sidebar";

// utils
import getLoggerList from "./utils/getLoggerList";
import getLoggerDetail from "./utils/getLoggerDetail";

// type
import { Logger, defaultLog } from "../../types/logger";

const { RangePicker } = DatePicker;
const { Option } = Select;

const LoggerComponent = () => {
  const { Header, Content, Sider } = Layout;

  const [list, setList] = useState<Logger[]>([]);
  const [selectedLogger, setSelectedLogger] = useState<Logger>(defaultLog);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<any>({
    date_start: moment().format("YYYY-MM-DD 00:00:00"),
    date_end: moment().format("YYYY-MM-DD 23:59:59"),
    log_level: "",
    company_name: "",
    username: "",
    message: "",
    response_code: "",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });

  const handleSelectLogger = async (record: any) => {
    setModalVisible(true);
    let getLogger = defaultLog;
    try {
      getLogger = await getLoggerDetail(record.id);
    } catch (error) {
      console.error(error);
    }

    setSelectedLogger(getLogger);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleTableChange = async (pagination: any, filters: any, sorter: any) => {
    try {
      setIsLoading(true);
      const listLogger = await getLoggerList({
        ...filter,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...pagination,
        ...filters,
      });
      setList(listLogger.logs);
      setPagination({
        ...pagination,
        total: listLogger.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const columns = [
    {
      title: "Tanggal",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Level",
      dataIndex: "log_level",
      key: "log_level",
    },
    {
      title: "Path",
      dataIndex: "host",
      key: "host",
      render: (val: any, data: any) => {
        return (
          <>
            {data.host} <br /> {data.endpoint} <br /> {data.method} - {data.duration}ms
          </>
        );
      },
    },
    {
      title: "Apotek",
      dataIndex: "company_id",
      key: "company_id",
      render: (val: any, data: any) => {
        return (
          <>
            {data.company_id} /<br /> {data.company_name}
          </>
        );
      },
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (val: any, data: any) => {
        return (
          <>
            {data.response_code} /<br /> {data.message}
          </>
        );
      },
    },
    {
      title: "User",
      dataIndex: "created_by",
      key: "created_by",
      render: (val: any, data: any) => {
        return (
          <>
            {data.created_by} /<br /> {data.username}
          </>
        );
      },
    },
  ];

  const getLogger = async () => {
    try {
      setIsLoading(true);
      const listLogger = await getLoggerList({
        ...pagination,
        ...filter,
      });
      setList(listLogger.logs);
      setPagination({
        ...pagination,
        total: listLogger.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const renderDate = (date: string | null | undefined, includeHour?: boolean) => {
    const format = includeHour ? "DD MMM YYYY, HH:mm" : "DD MMM YYYY";
    return date ? moment(date).format(format) : "-";
  };

  const renderResponseCode = () => {
    return RESPONSE_CODE.map((k) => (
      <Option value={k.code} key={k.code}>
        {k.code} - {k.description}
      </Option>
    ));
  };

  const getFormattedList = useMemo(() => {
    let result = list.map((p: Logger) => {
      return {
        id: p.id,
        created_at: renderDate(p.created_at, true),
        log_level: p.log_level,
        host: p.host,
        endpoint: p.endpoint,
        method: p.method,
        company_id: p.company_id,
        company_name: p.company_name,
        request_header: p.request_header,
        request_query_string: p.request_query_string,
        request_body: p.request_body,
        payload: p.payload,
        response_header: p.response_header,
        response_body: p.response_body,
        response_code: p.response_code,
        message: p.message,
        duration: p.duration,
        created_by: p.created_by,
        username: p.username,
      };
    });

    return result;
  }, [list, filter]);

  const onDateChange = debounce((values: any, dateString: [string, string]) => {
    setFilter({
      ...filter,
      date_start: values[0].format("YYYY-MM-DD 00:00:00"),
      date_end: values[1].format("YYYY-MM-DD 23:59:59"),
    });
  }, 800);

  const onDateOK = debounce((values: any) => {}, 800);

  const onLevelChange = debounce((value) => {
    setFilter({
      ...filter,
      log_level: value,
    });
  });

  const onResponseCodeChange = debounce((value) => {
    setFilter({
      ...filter,
      response_code: value,
    });
  });

  const onSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }, 800);

  useEffect(() => {
    getLogger();
  }, [filter]);

  return (
    <>
      <Modal visible={modalVisible} onCancel={handleCloseModal} onOk={handleCloseModal} confirmLoading={isLoading} width={1000}>
        <LoggerDetail selectedLogger={selectedLogger} />
      </Modal>
      <Layout style={{ height: "100vh" }}>
        <Sider breakpoint='lg' collapsedWidth='0'>
          <Sidebar activeTab={"logger"} />
        </Sider>
        <Layout>
          <Header style={{ background: "#fff" }}>
            <h1>List Log</h1>
          </Header>
          <Content
            style={{
              padding: "24px",
              paddingBottom: "100px",
              overflowY: "scroll",
            }}
          >
            <Row style={{ marginBottom: "16px" }}>
              <Col span={6}>
                <RangePicker
                  defaultValue={[moment(), moment()]}
                  style={{ width: "100%" }}
                  onChange={onDateChange}
                  onOk={onDateOK}
                />
              </Col>
              <Col span={6}>
                <Select defaultValue='' style={{ width: "100%" }} onChange={onLevelChange}>
                  <Option value=''>ALL LEVEL</Option>
                  <Option value='INFO'>INFO</Option>
                  <Option value='WARNING'>WARNING</Option>
                  <Option value='ERROR'>ERROR</Option>
                  <Option value='CRITICAL'>CRITICAL</Option>
                </Select>
              </Col>
              <Col span={6}>
                <Input name='company_name' placeholder='Cari Apotek' onChange={onSearch} />
              </Col>
              <Col span={6}>
                <Input name='username' placeholder='Cari Pengguna' onChange={onSearch} />
              </Col>
              <Col span={6}>
                <Input name='message' placeholder='Cari message' onChange={onSearch} />
              </Col>
              <Col span={6}>
                <Select defaultValue='' style={{ width: "100%" }} onChange={onResponseCodeChange}>
                  {renderResponseCode()}
                </Select>
              </Col>
            </Row>

            <Table
              rowKey={(record) => record.id}
              dataSource={getFormattedList}
              columns={columns}
              pagination={pagination}
              loading={isLoading}
              onChange={handleTableChange}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleSelectLogger(record);
                  }, // click row
                };
              }}
            />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default LoggerComponent;

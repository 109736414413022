/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { Table, Row, Col, DatePicker } from "antd";

// utils
import getPharmacyLog from "./utils/getPharmacyLog";

// type
import { PharmacyLog } from "../../types/pharmacy";

// component
import { debounce } from "lodash";

type Props = {
  company_id: number;
};

const PharmacyLogComponent = (props: Props) => {
  const { company_id } = props;
  const { RangePicker } = DatePicker;

  const [list, setList] = useState<PharmacyLog[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] =
    useState<any>(/* {
    date_start: moment().format("YYYY-MM-DD 00:00:00"),
    date_end: moment().format("YYYY-MM-DD 23:59:59"),
  } */);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 50,
  });

  const handleTableChange = async (pagination: any, filters: any, sorter: any) => {
    try {
      setIsLoading(true);
      const listPharmacyLog = await getPharmacyLog(company_id, {
        ...filter,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...pagination,
        ...filters,
      });
      setList(listPharmacyLog.collections);
      setPagination({
        ...pagination,
        total: listPharmacyLog.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const columns = [
    {
      title: "Log At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "reason",
      key: "reason",
    },
  ];

  const getLog = async () => {
    try {
      setIsLoading(true);
      const listPharmacyLog = await getPharmacyLog(company_id, {
        ...pagination,
        ...filter,
      });
      setList(listPharmacyLog.collections);
      setPagination({
        ...pagination,
        total: listPharmacyLog.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };
  const renderType = (type: string, user: string) => {
    switch (type) {
      case "status":
        return "Ubah status apotek";
      case "is_daily_active":
        return "Daily Activity";
      case "is_internal":
        return "Apotek internal";
      case "is_payment":
        return "Pembayaran";
      case "active_until":
        if (user === "Xendit") {
          return "Bayar berlangganan";
        }
        return "Ubah masa aktif";
      case "created":
        return "Dibuat";
      default:
        return type;
    }
  };

  const renderValue = (type: string, value: string, user: string) => {
    switch (type) {
      case "status":
        return value;
      case "is_daily_active":
        return value === "1" ? "Active" : "Not Active";
      case "is_internal":
        return value === "1" ? "Internal" : "Client";
      case "is_payment":
        return value === "1" ? "Sudah Bayar" : "Belum Bayar";
      case "active_until":
      case "created":
      default:
        return value;
    }
  };

  const getFormattedList = useMemo(() => {
    let result: PharmacyLog[] = list.map((p: PharmacyLog) => {
      return {
        id: p.id,
        type: renderType(p.type, p.user),
        value: renderValue(p.type, p.value, p.user),
        created_at: p.created_at,
        user: p.user,
        title: p.title,
        reason: p.reason,
      };
    });

    return result;
  }, [list, filter]);

  useEffect(() => {
    getLog();
  }, [company_id, filter]);

  const onDateChange = debounce((values: any, dateString: [string, string]) => {
    setFilter({
      ...filter,
      date_start: values === null ? "" : values[0].format("YYYY-MM-DD 00:00:00"),
      date_end: values === null ? "" : values[1].format("YYYY-MM-DD 23:59:59"),
    });
  }, 800);

  const onDateOK = debounce((values: any) => {
    setFilter({
      ...filter,
      date_start: values === null ? "" : values[0].format("YYYY-MM-DD 00:00:00"),
      date_end: values === null ? "" : values[1].format("YYYY-MM-DD 23:59:59"),
    });
  }, 800);

  // const onSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFilter({ ...filter, [e.target.name]: e.target.value });
  // }, 800);

  return (
    <>
      <Row style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <RangePicker
            // defaultValue={[moment(), moment()]}
            style={{ width: "100%" }}
            onChange={onDateChange}
            onOk={onDateOK}
          />
        </Col>
      </Row>
      <Table
        rowKey={(record) => record.id}
        dataSource={getFormattedList}
        columns={columns}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default PharmacyLogComponent;

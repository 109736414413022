import { notification } from "antd";

import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

export const getMonitorLambdaList = async (params: any) => {
  try {
    const url = `${BASE_URL_BETA}/admin/monitor-lambda`;
    const response = await axios.get(url, { params: params });

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (err: any) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
    throw new Error(err);
  }
};

export const getMonitorLambdaLog = async (lambda_id: number, params: any) => {
  try {
    const url = `${BASE_URL_BETA}/admin/monitor-lambda/${lambda_id}`;
    const response = await axios.get(url, {
      params: params,
    });

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (err: any) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
    throw new Error(err);
  }
};

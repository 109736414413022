/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Layout, Table, Modal } from "antd";

// utils
import { getAnomalyDataList, getAnomalyDataDetailList } from "./utils/getAnomalyData";

// type
import { DataAnomaly, DataAnomalyDetail, defaultDataAnomaly, defaultDataAnomalyDetail } from "../../types/dataAnomaly";

// component
import AnomalyDetail from "./AnomalyDetail";
import Sidebar from "../../component/Sidebar";
import moment from "moment";

const DataAnomalyComponent = () => {
  const { Header, Content, Sider } = Layout;

  const [list, setList] = useState<DataAnomaly[]>([]);
  const [selectedDataAnomaly, setSelectedDataAnomaly] = useState<DataAnomaly>(defaultDataAnomaly);
  const [selectedDataAnomalyDetail, setSelectedDataAnomalyDetail] = useState<DataAnomalyDetail[]>(defaultDataAnomalyDetail);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filter] = useState<any>({
    data_type: "",
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 50,
  });

  const handleSelectDataAnomaly = async (record: any) => {
    setModalVisible(true);
    setSelectedDataAnomaly(record);
    const listDataAnomaly = await getAnomalyDataDetailList(record.id);
    setSelectedDataAnomalyDetail(listDataAnomaly.collections);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleTableChange = async (pagination: any, filters: any, sorter: any) => {
    try {
      setIsLoading(true);
      const listDataAnomaly = await getAnomalyDataList({
        ...filter,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...pagination,
        ...filters,
      });
      setList(listDataAnomaly.collections);
      setPagination({
        ...pagination,
        total: listDataAnomaly.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const columns = [
    {
      title: "Tanggal",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Tipe Data",
      dataIndex: "data_type",
      key: "data_type",
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "200px",
      render: (val: boolean) => {
        return <>{val ? "Passed" : "Not Passed"}</>;
      },
    },
  ];

  const getDataAnomaly = async () => {
    try {
      setIsLoading(true);
      const listDataAnomaly = await getAnomalyDataList({
        ...pagination,
        ...filter,
      });
      setList(listDataAnomaly.collections);
      setPagination({
        ...pagination,
        total: listDataAnomaly.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const renderDate = (date: string | null | undefined, includeHour?: boolean) => {
    const format = includeHour ? "DD MMM YYYY, HH:mm" : "DD MMM YYYY";
    return date ? moment(date).format(format) : "-";
  };

  const getFormattedList = useMemo(() => {
    let result = list.map((p: DataAnomaly) => {
      return {
        id: p.id,
        created_at: renderDate(p.created_at, true),
        data_type: p.data_type,
        label: p.label,
        query: p.query,
        status: p.status,
      };
    });

    return result;
  }, [list, filter]);

  useEffect(() => {
    getDataAnomaly();
  }, [filter]);

  return (
    <>
      <Modal visible={modalVisible} onCancel={handleCloseModal} onOk={handleCloseModal} confirmLoading={isLoading} width={1000}>
        <AnomalyDetail selectedAnomaly={selectedDataAnomaly} AnomalyDetail={selectedDataAnomalyDetail} />
      </Modal>
      <Layout style={{ height: "100vh" }}>
        <Sider breakpoint='lg' collapsedWidth='0'>
          <Sidebar activeTab={"data-anomaly"} />
        </Sider>
        <Layout>
          <Header style={{ background: "#fff" }}>
            <h1>Data Anomali</h1>
          </Header>
          <Content
            style={{
              padding: "24px",
              paddingBottom: "100px",
              overflowY: "scroll",
            }}
          >
            <Table
              rowKey={(record) => record.id}
              dataSource={getFormattedList}
              columns={columns}
              pagination={pagination}
              loading={isLoading}
              onChange={handleTableChange}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleSelectDataAnomaly(record);
                  }, // click row
                };
              }}
            />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default DataAnomalyComponent;

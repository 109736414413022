import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";

type ModalCOnfirmType = {
  onConfirm: () => void;
  isLoading: boolean;
};

const confirm = (e: ModalCOnfirmType) => {
  Modal.confirm({
    title: "Confirmation",
    icon: <ExclamationCircleOutlined />,
    content: "Apakah kamu yakin ingin menyimpan?",
    okText: "Ok",
    cancelText: "Cancel",
    onOk: () => e.onConfirm(),
  });
};

const ModalConfirmationPromo = (e: ModalCOnfirmType) => {
  return (
    <Space>
      <Button loading={e?.isLoading} onClick={() => confirm(e)} type='primary'>
        Save
      </Button>
    </Space>
  );
};

export default ModalConfirmationPromo;
